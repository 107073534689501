@import "variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?tsybji');
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?tsybji#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?tsybji') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?tsybji') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?tsybji##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-vector {
  &:before {
    content: $icon-vector;     
    color: #365072;
  }
}
.icon-building {
  &:before {
    content: $icon-building; 
  }
}
.icon-person {
  &:before {
    content: $icon-person; 
  }
}
.icon-trash-2 {
  &:before {
    content: $icon-trash-2; 
  }
}
.icon-trash-3 {
  &:before {
    content: $icon-trash-3; 
  }
}
.icon-eye {
  &:before {
    content: $icon-eye; 
  }
}
.icon-plus {
  &:before {
    content: $icon-plus; 
  }
}
.icon-trash {
  &:before {
    content: $icon-trash; 
  }
}
.icon-logout {
  &:before {
    content: $icon-logout; 
  }
}
.icon-phone {
  &:before {
    content: $icon-phone; 
  }
}
.icon-lock .path1 {
  &:before {
    content: $icon-lock-path1;  
    color: rgb(0, 0, 0);  
    opacity: 0.887;
  }
}
.icon-lock .path2 {
  &:before {
    content: $icon-lock-path2;  
    margin-left: -0.7529296875em;  
    color: rgb(0, 0, 0);  
    opacity: 0.869;
  }
}
.icon-pen-2 {
  &:before {
    content: $icon-pen-2; 
  }
}
.icon-company {
  &:before {
    content: $icon-company; 
  }
}
.icon-earth {
  &:before {
    content: $icon-earth; 
  }
}
.icon-folder {
  &:before {
    content: $icon-folder; 
  }
}
.icon-close {
  &:before {
    content: $icon-close; 
  }
}
.icon-download {
  &:before {
    content: $icon-download; 
  }
}
.icon-pdf {
  &:before {
    content: $icon-pdf; 
  }
}
.icon-star-outline {
  &:before {
    content: $icon-star-outline; 
  }
}
.icon-star {
  &:before {
    content: $icon-star; 
  }
}
.icon-edit {
  &:before {
    content: $icon-edit; 
  }
}
.icon-location {
  &:before {
    content: $icon-location; 
  }
}
.icon-chevron-up {
  &:before {
    content: $icon-chevron-up; 
  }
}
.icon-title {
  &:before {
    content: $icon-title; 
  }
}
.icon-clock-2 {
  &:before {
    content: $icon-clock-2; 
  }
}
.icon-Shape {
  &:before {
    content: $icon-Shape; 
  }
}
.icon-chevron-down {
  &:before {
    content: $icon-chevron-down; 
  }
}
.icon-bell {
  &:before {
    content: $icon-bell; 
  }
}
.icon-arrow-right1 {
  &:before {
    content: $icon-arrow-right1; 
  }
}
.icon-arrow-left {
  &:before {
    content: $icon-arrow-left; 
  }
}
.icon-close-circle {
  &:before {
    content: $icon-close-circle; 
  }
}
.icon-list-center {
  &:before {
    content: $icon-list-center; 
  }
}
.icon-more {
  &:before {
    content: $icon-more; 
  }
}
.icon-messages {
  &:before {
    content: $icon-messages; 
  }
}
.icon-clock {
  &:before {
    content: $icon-clock; 
  }
}
.icon-loop {
  &:before {
    content: $icon-loop; 
  }
}
.icon-doc {
  &:before {
    content: $icon-doc; 
  }
}
.icon-arrow-right {
  &:before {
    content: $icon-arrow-right; 
  }
}
.icon-home {
  &:before {
    content: $icon-home; 
  }
}
.icon-gear {
  &:before {
    content: $icon-gear; 
  }
}
.icon-calendar {
  &:before {
    content: $icon-calendar; 
  }
}
.icon-burger {
  &:before {
    content: $icon-burger; 
  }
}
.icon-success {
  &:before {
    content: $icon-success; 
  }
}
.icon-case {
  &:before {
    content: $icon-case; 
  }
}
.icon-character {
  &:before {
    content: $icon-character; 
  }
}
.icon-camera {
  &:before {
    content: $icon-camera; 
  }
}
.icon-pen {
  &:before {
    content: $icon-pen; 
  }
}
.icon-user {
  &:before {
    content: $icon-user; 
  }
}
.icon-mail {
  &:before {
    content: $icon-mail; 
  }
}
.icon-mail-2 {
  &:before {
    content: $icon-mail-2; 
  }
}
.icon-check {
  &:before {
    content: $icon-check; 
  }
}

