$icomoon-font-family: "talent" !default;
$icomoon-font-path: "fonts" !default;

$icon-vector: "\e933";
$icon-building: "\e930";
$icon-person: "\e92f";
$icon-trash-2: "\e931";
$icon-trash-3: "\e932";
$icon-eye: "\e906";
$icon-plus: "\e92d";
$icon-trash: "\e92e";
$icon-logout: "\e92c";
$icon-phone: "\e901";
$icon-lock-path1: "\e923";
$icon-lock-path2: "\e929";
$icon-pen-2: "\e928";
$icon-company: "\e92a";
$icon-earth: "\e92b";
$icon-folder: "\e924";
$icon-close: "\e925";
$icon-download: "\e926";
$icon-pdf: "\e927";
$icon-star-outline: "\e921";
$icon-star: "\e922";
$icon-edit: "\e91b";
$icon-location: "\e91c";
$icon-chevron-up: "\e91d";
$icon-title: "\e91e";
$icon-clock-2: "\e91f";
$icon-Shape: "\e920";
$icon-chevron-down: "\e910";
$icon-bell: "\e913";
$icon-arrow-right1: "\e916";
$icon-arrow-left: "\e917";
$icon-close-circle: "\e918";
$icon-list-center: "\e919";
$icon-more: "\e91a";
$icon-messages: "\e915";
$icon-clock: "\e909";
$icon-loop: "\e914";
$icon-doc: "\e908";
$icon-arrow-right: "\e90e";
$icon-home: "\e90a";
$icon-gear: "\e90b";
$icon-calendar: "\e90c";
$icon-burger: "\e90d";
$icon-success: "\e90f";
$icon-case: "\e911";
$icon-character: "\e912";
$icon-camera: "\e900";
$icon-pen: "\e902";
$icon-user: "\e903";
$icon-mail: "\e904";
$icon-mail-2: "\e905";
$icon-check: "\e907";

